/*
 * @copyright C UAB NFQ Technologies 2018
 *
 * This Software is the property of NFQ Technologies
 * and is protected by copyright law – it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * Contact UAB NFQ Technologies:
 * E-mail: info@nfq.lt
 * http://www.nfq.lt
 *
 */

import 'semantic-ui-css/components/modal';
import $ from 'jquery';

$.fn.extend({
    requireLoyaltyHolidayConfirmationForBranch() {
        this.each((idx, el) => {
            $(el).on('click', (evt) => {
                evt.preventDefault();

                const actionButton = $(evt.currentTarget);

                if (actionButton.is('a')) {
                    $('#confirmation-button').attr('href', actionButton.attr('href'));
                }

                if (actionButton.is('button')) {
                    $('#confirmation-button').on('click', (event) => {
                        event.preventDefault();

                        $('#loyalty-holiday-remove-branch-confirmation-modal').modal('show');

                        $('#loyalty-holiday-delete-branch-confirmation-button').on('click', () => {
                            actionButton.closest('form').submit();
                        });
                    });
                }

                $('#confirmation-modal').modal('show');
            });
        });
    },
});
