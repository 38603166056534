
if(typeof schedule_grid !== 'undefined') {

    if ($.isEmptyObject(schedule_grid[0])) {

        $('.empty-text').show();
    } else {

        lodSchedule(schedule_grid);
    }
}

function lodSchedule(grid) {
    let element = $('#schedule');
    let dataList = [];
    var sendData;

    change = function(object, cell, val) {
        var id = $(cell).prop('id').split('-');
        var x = id[0];
        var y = id[1];

        if(map_users[y] && map_rows[y] && map_columns[x]) {
            dataList.push({user: map_users[y], row: map_rows[y], type: map_columns[x], value: val});
        }

        if(dataList.length > 0) {
            update(element);
        }
    };

    function update(element)
    {
        clearTimeout(sendData);

        sendData = window.setTimeout(function() {
            $.ajax({
                'method': 'POST',
                'url': schedule_path,
                'data': {data: dataList}
            }).fail(function (){

                alert('Something went wrong!');
                element.find('td').addClass('readonly');
            });

            dataList = [];

        }, 1000);
    }

    element.jexcel({
        data: grid,
        rowDrag: true,
        columnSorting: false,
        allowInsertRow: false,
        allowInsertColumn: false,
        allowDeleteRow: false,
        allowDeleteColumn: false,
        onchange: change,
    });

    onStart(element);

    function onStart(element) {
        changeHeader(element);
        mergeColumnsForNameAndPosition(element);
        rotateColumnHeaders();
        readOnlyLastTotals(element);
        setBorders(element);
        setWeekends(element);
        setHolidays(element);
        setReadOnlyColumns(element);
        setReadOnlyRows(element);
    }

    function setReadOnlyColumns(element)
    {
        if (!can_edit) {
            element.find('tbody td').addClass('readonly');

            return;
        }

        var i;

        for (i = 0; i < read_only_columns.length; i++) {

            var read_only = parseInt(read_only_columns[i]) + 2;

            element.find('tbody tr td:nth-child('+ read_only +')').addClass('readonly');
        }
    }

    function setReadOnlyRows(element)
    {
        var i;

        for (i = 0; i < read_only_rows.length; i++) {

            var read_only = parseInt(read_only_rows[i]);

            element.find('tbody tr:nth-child('+ read_only +') td').addClass('readonly');
        }
    }

    function setWeekends(element) {

        var i;

        for (i = 0; i < schedule_weekends.length; i++) {

            var weekend = parseInt(schedule_weekends[i]) + 5;

            element.find('tbody tr td:nth-child('+ weekend +')').addClass('weekend');
            element.find('tbody tr:last-child td:nth-child(-n+'+ (schedule_days+5) +')').removeClass('weekend');
        }
    }

    function setHolidays(element) {

        var i;

        for (i = 0; i < schedule_holidays.length; i++) {

            var holiday = parseInt(schedule_holidays[i]) + 5;

            element.find('tbody tr td:nth-child('+ holiday +')').addClass('holiday');
        }

        element.find('tbody tr:last-child td:nth-child(-n+'+ (schedule_days+5) +')').removeClass('holiday');
    }

    function mergeColumnsForNameAndPosition()
    {
        $('#schedule tbody tr:nth-child(3n+1) td:nth-child(2)').attr('rowspan', 3);
        $('#schedule tbody tr:nth-child(3n+1) td:nth-child(3)').attr('rowspan', 3);
        $('#schedule tbody tr:nth-child(3n+1) td:nth-child(4)').attr('rowspan', 3);
        $('#schedule tbody tr:nth-child(3n+1) td:nth-child(5)').attr('rowspan', 3);

        $('#schedule tbody tr:nth-child(3n+2) td:nth-child(2)').hide();
        $('#schedule tbody tr:nth-child(3n+2) td:nth-child(3)').hide();
        $('#schedule tbody tr:nth-child(3n+2) td:nth-child(4)').hide();
        $('#schedule tbody tr:nth-child(3n+2) td:nth-child(5)').hide();
        $('#schedule tbody tr:nth-child(3n+3) td:nth-child(2)').hide();
        $('#schedule tbody tr:nth-child(3n+3) td:nth-child(3)').hide();
        $('#schedule tbody tr:nth-child(3n+3) td:nth-child(4)').hide();
        $('#schedule tbody tr:nth-child(3n+3) td:nth-child(5)').hide();
    }

    function setBorders(element)
    {
        element.find('tbody tr td:nth-child('+ (schedule_days+5) +')').addClass('border-thick right');
        element.find('tbody tr td:nth-child('+ (schedule_days+14) +')').addClass('border-thick right');
        element.find('tbody tr td:nth-child('+ (schedule_days+17) +')').addClass('border-thick right');
        element.find('tbody tr td:nth-child('+ (schedule_days+20) +')').addClass('border-thick right');
        element.find('tbody tr:last-child td:nth-child(-n+'+ (schedule_days+4) +')').addClass('border-none right');
    }

    function readOnlyLastTotals(element)
    {
        element.find('tbody tr:last-child td').addClass('readonly');
    }

    function changeHeader(element)
    {
        element.find('thead').after($('.schedule-header-container table').html());
    }

    function rotateColumnHeaders()
    {
        var header_height = 0;

        $('table .rotate > div').each(function() {
            if ($(this).outerWidth() > header_height) header_height = $(this).outerWidth();
        });

        $('table .rotate').height(header_height-48);
    }
}
