import 'jquery/dist/jquery.min.js';
import 'semantic-ui-css/semantic.min.js';
import '@chenfengyuan/datepicker/dist/datepicker.min.js';
import 'jexcel/dist/js/excel-formula.min.js';
import 'jexcel/dist/js/jquery.jexcel.js';
import '../js/schedule.js';
import '../js/sylius-app.js';
import '../js/select2.full.min.js';
import './../../../../vendor/tetranz/select2entity-bundle/Resources/public/js/select2entity';
import style2 from '../styles/index.scss';
import style from '../sass/main.scss';

var defaultDateFormat = 'YYYY-MM-DD';
/** @TODO move to back end */
var defaultYearMonthFormat = 'YYYY-MM';

(function ($) {

    $(document).ready(function () {
        initializeDatePickers();
        handleNotifications();
        initializeElementHiding();
        initializeModals();
        initializeBulkSelectAll();
    });

    function initializeDatePickers() {
        $('.month[data-toggle="datepicker"]').datepicker({
            language: 'lt-LT',
            format: defaultYearMonthFormat,
            autoHide: true,
        });

        $('[data-toggle="datepicker"]').datepicker({
            language: 'lt-LT',
            format: defaultDateFormat,
            autoHide: true,
        });

        initializeClear();

        function initializeClear() {
            $('.clearable[data-toggle="datepicker"]').wrap('<span class="clear-wrapper"></span>');
            $('.clearable[data-toggle="datepicker"]').after('<i class="window close icon datepicker-clear" title="Clear"></i>');
            $('.clear-wrapper .datepicker-clear').click(function () {
                $(this).parent().find('[data-toggle="datepicker"]').val('');
            });
        }
    }

    function handleNotifications() {
        $('#notification-menu .remove.link').on('click', function () {

            let el = $(this);
            let dismissTarget = $(el.attr('data-dismiss'));
            let dataTarget = el.attr('data-target');

            markNotificationAsShown(dataTarget);
            dismissTarget.fadeOut("100", function () {
                $(this).remove();
                noMessages();
            });

            function markNotificationAsShown(dataTarget) {
                $.ajax({
                    method: "POST",
                    url: dataTarget,
                });
            }

            function noMessages() {
                if (!$('.notification-menu-wrapper .message').length) {
                    $('.notification-menu-wrapper .bell.icon').addClass('outline');
                }
            }
        });
    }

    function initializeElementHiding() {
        $('.hide-element').on('change', function (ev) {
            let target;
            let hide = false;

            let el = $(ev.target);

            if (el.data('target')) {
                target = $(el.data('target'));
            } else {
                console.log('No target set for hiding!');
                $(this).off();
                return;
            }

            let value = el.val();
            let actionValue = el.data('hide-on');

            if (!actionValue) {
                console.log('No value set to hide on!');
                $(this).off();
                return;
            }

            if (actionValue.charAt(0) === '!') {
                actionValue = actionValue.substring(1);
                hide = (value !== actionValue);
            } else {
                hide = (value === actionValue);
            }

            if (hide) {
                target.hide();

                target.find('.hide-required').removeAttr('required');
            } else {
                target.show();

                target.find('.hide-required').attr('required', 'required');
            }
        }).change();
    }

    function initializeModals() {
        $('.document-rejection-modal-button').click(function () {
            let modal = $('#documentRejectionModal');
            let form = modal.find('form');
            let reason = modal.find('.reason');

            let url = $(this).data('link');

            form.attr('action', url);
            reason.val('');

            modal.modal('show');
        });
    }

    function initializeBulkSelectAll() {
        $('.bulk-select-all-checkbox').change(function (ev) {
            const val = ev.target.checked;
            const checkboxes = $(ev.target).closest('table').find('.bulk-select-checkbox');

            checkboxes.prop('checked', val);
        });
    }

})(jQuery);
